@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Poppins:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply scroll-smooth;
  }
  
  body {
    @apply font-sans text-gray-900 antialiased;
  }
}

@layer components {
  .container-padding {
    @apply px-4 sm:px-6 lg:px-8;
  }
  
  .section-padding {
    @apply py-16 md:py-24;
  }
  
  .heading-1 {
    @apply text-4xl md:text-5xl lg:text-6xl font-display font-bold;
  }
  
  .heading-2 {
    @apply text-3xl md:text-4xl font-display font-bold;
  }
  
  .heading-3 {
    @apply text-2xl md:text-3xl font-display font-semibold;
  }
}